import { getTracking } from '@vp/tracking'

const trackEvent = (params: { label: string, url: string }) => {
  const tracking = getTracking()

  if (!tracking) {
    return
  }

  const { label, url } = params
  tracking.track(
    'My Account Sidebar Clicked',
    {
      route: 'My Account',
      category: 'My Account',
      label,
      url,
    },
    {}
  )
}

export { trackEvent }
