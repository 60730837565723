import { Divider, FlexBox, LeftHandNavigation, LeftHandNavigationHeader, ListItem, Link, LinkList, LinkListHeading, Typography } from '@vp/swan'
import { useSwanStyleKeys } from '@vp/ubik-context'
import React from 'react'
import NewPageTag from './NewPageTag'
import { trackEvent } from '../utils/tracking'

interface SidebarGroups {
  id: string;
  title: string;
  links: {
    id: string;
    text: string;
    url: string;
    newUntil?: string;
  }[];
}

export interface Props {
  sidebarTitle: string;
  sidebarGroups: SidebarGroups[];
  newTagText: string;
}

export const Fragment = ({ sidebarTitle, sidebarGroups, newTagText }: Props) => {
  useSwanStyleKeys(['core'])

  return (
    <FlexBox flexDirection='column'>
      <LeftHandNavigation mb='between-sections'>
        <LeftHandNavigationHeader mt='0'>{sidebarTitle}</LeftHandNavigationHeader>
        {sidebarGroups.map((group) => (
          <React.Fragment key={group.id}>
            <Divider />
            {group.title && <LinkListHeading id={group.id}>{group.title}</LinkListHeading>}
            <LinkList aria-labelledby={group.id}>
              {group.links.map((item) => (
                <ListItem key={item.id}>
                  <Typography as='span' fontSkin='body-small'>
                    <Link
                      href={item.url}
                      onClick={() => trackEvent({ label: item.text, url: item.url })}
                    >{item.text}
                    </Link>
                  </Typography>
                  <NewPageTag renderUntil={item.newUntil ?? ''} text={newTagText} />
                </ListItem>
              ))}
            </LinkList>
          </React.Fragment>
        ))}
      </LeftHandNavigation>
    </FlexBox>
  )
}
