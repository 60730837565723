import { Callout } from '@vp/swan'
import React from 'react'

const NewPageTag = (props: { renderUntil: string; text: string }) => {
  const { renderUntil, text } = props

  const isNew = renderUntil && new Date(renderUntil) > new Date()

  if (!isNew || !text) return null

  return (
    <span>
      <Callout ml='3' skin='announcement' variant='inverse'>
        {props.text}
      </Callout>
    </span>
  )
}

export default NewPageTag
